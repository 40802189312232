import React from "react";
import Link from "../../components/Link";
import { Dialog } from "../../components/Dialog";

const AdminMenuDialog = ({ href = "/", children, ...props }) => {
  return (
    <Link display="flex" href={href}>
      <Dialog
        w="100%"
        backgroundColor="saweria-green.light"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        py={"2em"}
        px={"3em"}
        _hover={{
          transform: "translate(0rem, 0.1rem)",
          boxShadow: "pressed",
          transition: "box-shadow .1s , transform .2s ",
        }}
        {...props}
      >
        {children}
      </Dialog>
    </Link>
  );
};

export { AdminMenuDialog };
