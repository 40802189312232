import React from "react";
import { Header2 } from "../components/Header";
import { Dialog } from "../components/Dialog";
import { Large, P } from "../components/Type";
import Button from "../components/Button";

import Head from "../components/Head";
import {
  Flex,
  Box,
  Stack,
  OrderedList,
  List,
  ListItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { Footer } from "../components/Footer";
import { Folder, FolderTitle } from "../components/Folder";
import { AdminMenuDialog } from "../views/AdminMenu/AdminMenuDialog";
import Link from "../components/Link";

import Image from "next/image";
import { Announcement } from "../components/Announcement";

import chicken from "../assets/chicken_money.svg";
import doggo from "../assets/doggo_working.svg";
import capy from "../assets/capy_happy.svg";
import background3 from "../assets/background3.svg";
import racoonHappy from "../assets/raccoon_happy.svg";
import chickenSad from "../assets/chicken_sad.svg";
import { loadCatalog } from "../i18n";
import { Trans, t, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export async function getStaticProps({ params, ...ctx }) {
  const translation = await loadCatalog(ctx.locale);
  return { props: { translation } };
}

const Mereka = (props) => {
  const userLinks = [
    { name: "Naufal Wi", username: "naufalwi" },
    { name: "Kevin Anggara", username: "kevin" },
    { name: "izzy", username: "izzy" },
    { name: "Eno Bening", username: "indogegewepe" },
    { name: "Gema Show Indo", username: "gemashowindo" },
    { name: "Corneval", username: "corneval" },
    { name: "Edelyn", username: "edelyn" },
    { name: "The Jooomers", username: "hansrattlexnake" },
    { name: "Jeixyjee", username: "jeixyjee" },
    { name: "raybuattrailer", username: "raybuattrailer" },
    { name: "cjmofficial", username: "cjmofficial" },
    { name: "inayma", username: "inayma" },
    { name: "David Beat", username: "davidbeatt" },
  ];
  let leftLink = [];
  let rightLink = [];

  userLinks.forEach(({ name, username }, index) => {
    if (index < userLinks.length / 2) {
      leftLink.push(
        <Link
          key={`link-to-${username}`}
          href={"[username]"}
          linkAs={`${username}`}
          marginBottom={2}
        >
          {name}
        </Link>
      );
    } else {
      rightLink.push(
        <Link
          key={`link-to-${username}`}
          href={"[username]"}
          linkAs={`${username}`}
          marginBottom={2}
        >
          {name}
        </Link>
      );
    }
  });

  return (
    <Flex direction="column" {...props}>
      <Dialog
        alignSelf="center"
        w={["90%", "60%"]}
        display="flex"
        flexDirections="row"
        backgroundColor="saweria-yellow.normal"
        alignItems="center"
        mb={5}
      >
        <Box flexShrink={0} flexGrow={0} flexBasis={"30%"}>
          <Image src={doggo} />
        </Box>
        <Large
          mb="0"
          textAlign="center"
          flexGrow={1}
          flexShrink={0}
          flexBasis={"60%"}
          fontSize={["l", "xl"]}
        >
          mereka yang sudah bergabung
        </Large>
      </Dialog>
      <Flex paddingY={7}>
        <Box flexBasis="50%">
          <Box position="fixed">
            <Image src={background3} />
          </Box>
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            style={{
              backgroundImage: `url(${require("../assets/background3.svg")})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {leftLink}
          </Flex>
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          style={{
            backgroundImage: `url(${require("../assets/background1.svg")})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          flexBasis="50%"
        >
          {rightLink}
          dan masih banyak lagi!
        </Flex>
      </Flex>
    </Flex>
  );
};

const Header = (props) => {
  return (
    <Box textAlign="center" marginTop={3} {...props}>
      <Header2 />
      <Large marginY={5}>
        <Trans>Jembatan interaksi dengan penontonmu!</Trans>
      </Large>
      <Flex direction="row" justifyContent="center">
        <Button
          href="/login"
          label="Login"
          backgroundColor="saweria-green.normal"
        />
        <Box w={8} />

        <Button
          href="/register"
          label={t`Daftar`}
          backgroundColor="saweria-yellow.normal"
        />
      </Flex>
    </Box>
  );
};

const Description = (props) => {
  return (
    <Dialog {...props}>
      <P marginBottom={3}>
        <Trans>
          Saweria membantu kamu mendapatkan dukungan finansial dari penggemar
          karyamu dengan moda pembayaran di bawah ini:
        </Trans>
      </P>
      <Flex
        direction="row"
        alignItems="center"
        marginBottom={5}
        justifyContent="space-evenly"
      >
        <Box h="100%">
          <Box>
            🇮🇩 <Trans>Indonesia</Trans>
          </Box>
          <List styleType="disc" fontSize={["l", "xl", "xl"]}>
            <ListItem>GOPAY</ListItem>
            <ListItem>OVO</ListItem>
            <ListItem>DANA</ListItem>
            <ListItem>LINKAJA</ListItem>
            <ListItem>QRIS</ListItem>
          </List>
        </Box>

        <Box h="100%">
          <Box>
            🇵🇭 <Trans>Filipina</Trans>
          </Box>
          <List styleType="disc" fontSize={["l", "xl", "xl"]}>
            <ListItem>MAYA</ListItem>
            <ListItem>GCASH</ListItem>
            <ListItem>SHOPEEPAY</ListItem>
            <ListItem>GRABPAY</ListItem>
          </List>
        </Box>

        <Box flexBasis={["30%", "20%"]}>
          <Image layout="responsive" src={chicken} alt="chicken" />
        </Box>
      </Flex>
      <P>
        <Trans>
          Dana dapat dengan mudah dicairkan ke semua rekening bank dan e-wallet
          di Indonesia / Filipina
        </Trans>
      </P>
    </Dialog>
  );
};

const Cara = (props) => {
  return (
    <Folder
      {...props}
      title={() => {
        return (
          <FolderTitle backgroundColor="saweria-pink.normal">
            <Large fontSize={["s", "m", "l"]} textAlign="right" marginY={0}>
              <Trans>cara memulai</Trans>
            </Large>
          </FolderTitle>
        );
      }}
    >
      <OrderedList>
        <ListItem>
          <Trans>Daftarkan dirimu</Trans>
        </ListItem>
        <ListItem>
          <Trans>Verifikasi akun kamu</Trans>
        </ListItem>
        <ListItem>
          <Trans>Atur overlay yang ingin digunakan</Trans>
        </ListItem>
        <ListItem>
          <Trans>Jangan lupa pasang QR code atau link saweriamu</Trans>
        </ListItem>
        <ListItem>
          <Trans>Sapa dan terima dukungan dari penggemarmu!</Trans>
        </ListItem>
      </OrderedList>
    </Folder>
  );
};

const SiapBergabung = (props) => {
  return (
    <Flex direction="column" alignItems="center" {...props}>
      <Box transform={["translate(1.5em,0)", "translate(3em,0)"]} w={"50%"}>
        <Image src={capy} alt="Happy Capybara" mb={2} />
      </Box>
      <Large mb={2} fontSize={"2xl"} textAlign="center">
        <Trans>siap bergabung bersama saweria?</Trans>
      </Large>

      <Button
        href="/register"
        label={t`Daftar`}
        fontSize="xl"
        backgroundColor="saweria-yellow.normal"
      />
    </Flex>
  );
};

const Bingung = (props) => {
  return (
    <AdminMenuDialog
      {...props}
      w="100%"
      href="/faq"
      textAlign="center"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-evenly"
      backgroundColor="saweria-pink.light"
    >
      <Box w="15%">
        <Image src={chickenSad} />
      </Box>
      <Flex direction="column" justifyContent="center">
        <Large>
          <Trans>bingung?</Trans>
        </Large>
        <Large>
          <Trans>ada pertanyaan?</Trans>
        </Large>
        <Large mb={0}>
          <Trans>cek FAQ kami dulu</Trans>
        </Large>
      </Flex>
    </AdminMenuDialog>
  );
};

const Pengumuman = (props) => {
  return (
    <Folder
      id="pengumuman"
      {...props}
      backgroundColor="saweria-pink.light"
      title={() => {
        return (
          <FolderTitle backgroundColor="saweria-purple.light">
            <Large fontSize={["s", "m", "l"]} textAlign="right" marginY={0}>
              Pengumuman
            </Large>
          </FolderTitle>
        );
      }}
    >
      Mulai 1 JUNI 2020, potongan dukungan yang masuk menggunakan moda
      pembayaran OVO akan berubah menjadi 6%, dikarenakan adanya kenaikan biaya
      dari pihak OVO. Tidak ada perubahan biaya pada moda pembayaran lainnya
      (5%). Terima kasih.
    </Folder>
  );
};

const Pricing = (props) => {
  return (
    <Folder
      {...props}
      title={() => {
        return (
          <FolderTitle backgroundColor="saweria-green.normal">
            <Large fontSize={["s", "m", "l"]} textAlign="right" marginY={0}>
              pricing
            </Large>
          </FolderTitle>
        );
      }}
    >
      <Flex direction="row" justifyContent="space-evenly">
        <Flex
          display="flex"
          paddingLeft={3}
          flexBasis={"50%"}
          flexGrow={1}
          direction="column"
          justifyContent="space-evenly"
        >
          <P>
            <Trans>
              Setiap transaksi masuk akan terkena biaya sebesar 5%-6% (10% untuk
              Filipina) dengan batas minimum 150 rupiah / 1.5 peso
            </Trans>
          </P>
          <P>
            <Trans>
              Pencairan dana menuju rekening bank/e-wallet streamer akan terkena
              biaya sebesar IDR 5.000 / PHP 50
            </Trans>
          </P>
        </Flex>
        <Flex flexBasis="30%" flexGrow={0} alignItems="center" marginLeft="1em">
          <Image src={racoonHappy} />
        </Flex>
      </Flex>
    </Folder>
  );
};

const Home = () => {
  const { _ } = useLingui();
  return (
    <>
      <Head title={_(msg`Jembatan interaksi dengan penontonmu!`)}></Head>
      <Announcement></Announcement>
      <Flex
        alignItems="center"
        justifyContent="center"
        direction="column"
        paddingX={[3, 3, 0]}
      >
        <Stack spacing={8} width={["100%", "100%", "40%"]}>
          <Header />
          <Description />
          {/* <Pengumuman></Pengumuman> */}
          {/* <Mereka /> */}
          <Cara />
          <SiapBergabung />
          <Pricing />
          <Bingung />

          <SimpleGrid columns={[1, 1, 2]} spacing={8}>
            <Button
              label="Tutorial"
              href="/tutorial"
              textAlign="center"
              backgroundColor="saweria-yellow.normal"
            ></Button>
            <Button
              href="/terms"
              textAlign="center"
              label={t`Syarat dan Ketentuan`}
              backgroundColor="saweria-green.normal"
            ></Button>
          </SimpleGrid>

          <Footer></Footer>
        </Stack>
      </Flex>
    </>
  );
};

export default Home;
